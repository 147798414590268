
import { mapGetters,mapActions } from 'vuex'
export default {
  name: "PurchaseButton",
  props: {
    classroom: {
      default: 'false',
      type: String,
    },
    buttonclass: {
      default: '',
      type: String,
    },
    text: {
      default: 'Book Course Now',
      type: String,
    },
    classroomText: {
      default: 'View dates & times',
      type: String,
    }
  },
  computed: {
    ...mapGetters({
      activeModal : 'pages/getActiveModal',
    }),
  },
  methods: {
    ...mapActions({
      updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
    }),
    hideModal(){
      this.updateActiveModal(null)
      console.log('Active Modal: ',this.activeModal)
    }
  },
}
