import { render, staticRenderFns } from "./PurchaseButton.vue?vue&type=template&id=3c32f0fd&"
import script from "./PurchaseButton.vue?vue&type=script&lang=js&"
export * from "./PurchaseButton.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseButton.vue?vue&type=style&index=0&id=3c32f0fd&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartBookButton: require('/opt/build/repo/components/Cart/BookButton.vue').default})
