
import { mapGetters, mapActions } from 'vuex'
import productData from '@/data/products.json'
// import bannerData from '@/data/banner.json'
// let mountedCount = 0
export default {
name:'BookButton',
  props: {
    product_id: {
      type: String,
    },
    uri: {
      type: String,
    },
    classroom_id: {
      type: Number,
      default:null
    },
    center: {
      type: String,
      default: ''
    },
    pageId: {
      type: String,
    },
    virtual: {
      type: String,
    },
    hidden: {
      type: String,
      default:'false'
    },
    virtual_toggle: {
      type: String,
    },
    button_text: {
      type: String,
    },
    buttonclass:{
      type:String,
      default:'large'
    }
  },
  data() {
    return {
        pageid: '',
        classroom: null,
        isLoading: false,
        // randomId: 1,
        // mountedCnt: mountedCount
    }
  },

  fetchKey: 'classrooms-button',
  computed: {
    ...mapGetters({
      length: 'cart/getNumberOfProducts',
      showModal:'cart/showAdded'
    }),
    product(){
      const pid =
      this.$props.product_id ||
      this.$page?.productId ||
      this.$store.state.pages.page.productId
      

      let getProduct = {}

      getProduct = productData.find((o) => {
        return o.id.toString() === pid
      })

      getProduct.classroomId = this.$props.classroom_id || null
      return getProduct

    },
    priceData(){
      const getPriceData = this.$options.filters.priceData(
          this.product
      )
      // this.product.onSale = getPriceData.saleType !== 'none'
      return getPriceData
    }
  },
  mounted(){
    // this.randomId = Math.floor(Math.random()*(100-1+1)+1);
    // this.mountedCnt = ++mountedCount // increment "mountedCount"
  },
  methods: {
    ...mapActions({
      addToCart:'cart/addToCartServer'
    }),
    addToCartLoading(data){
      this.isLoading = true
      return this.addToCart(data).then(res => {
        this.isLoading = false
      }).catch(err => {
        console.log(err);
        this.isLoading = false
      })
    }
  }
}
