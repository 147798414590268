
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: {
    product: {
      type: Object,
      default() {
            return {}
        }
    },
    price: {
      type: Object,
      default() {
            return {}
        }
    },
    message: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      cartLoading: 'cart/getLoading',
      regionClicked : 'cart/getRegionClicked'
    })
  },
  watch: {
    'cartLoading'(newVal, oldVal){
      console.log('Loading changing: ',newVal, oldVal)
      this.isLoading = newVal
    }
  },
  methods: {
    ...mapMutations({
      hide: 'cart/hideAdded', // map `this.add()` to `this.$store.commit('increment')`
    }),
  },
}
